
import { defineComponent } from "vue";

export default defineComponent({
  props: ["filterteachercardinformation", "teachercardinformation"],
  data() {
    return {
      message: "",
    };
  },
  methods: {
    setFilterTeacher(query: string) {
      this.$emit("emitquery", query);
    },
  },
});
