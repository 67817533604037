
import { defineComponent } from "vue";
export default defineComponent({
  data() {
    return {
      // imgProperty: "",
    };
  },
  computed: {
    imgProperty() {
      return require("@/assets/icons/flags/flag_" +
        this.RoundImageProperty +
        ".svg");
    },
  },
  // mounted() {
  //   this.imgProperty = require("@/assets/icons/flags/flag_" +
  //     this.RoundImageProperty +
  //     ".svg");
  // },
  // updated() {
  //   this.imgProperty = require("@/assets/icons/flags/flag_" +
  //     this.RoundImageProperty +
  //     ".svg");
  // },
  props: ["RoundImageProperty"],
});
