
import { defineComponent } from "vue";
import CategoryCard from "@/components/Teachers/CategoryCard.vue";
import PopularTeacherCard from "@/components/Teachers/PopularTeacherCard.vue";
import Template7 from "@/views/templates/Template7.vue";

export default defineComponent({
  components: {
    CategoryCard,
    PopularTeacherCard,
    Template7,
  },
  data() {
    return {
      categoryLanguageName: "English Teachers",
      categoryLanguageMap: {
        en: "English Teachers",
        jp: "日本語教師",
        kr: "한국어 선생님",
        es: "Maestros de Español",
        de: "Deutschlehrer",
        fr: "Professeur de Français",
        vi: "giáo viên việt nam",
        ielts: "IELTS Teachers",
      } as { [key: string]: string },
      categoryLanguageLabel: 0,
      categoryTeacher: [],
      tags: [],
      loading: 0,
    };
  },
  methods: {
    getCategoryLanguage(language: string) {
      this.categoryLanguageName = this.categoryLanguageMap[language];
    },
    getLabelLanguage(number: any) {
      this.categoryLanguageLabel = number;
    },
    getCategoryTeacher(teacher: any, tags: any) {
      this.categoryTeacher = teacher;
      this.tags = tags;
    },
    getLoading(number: number) {
      this.loading = number;
    },
  },
});
