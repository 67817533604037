
import { defineComponent } from "vue";
import RoundImageFlag from "@/components/image/RoundImageFlag.vue";

export default defineComponent({
  name: "TeacherCard",
  props: ["teacherCardContent"],
  data() {
    return {
      name: [] as any,
    };
  },
});
