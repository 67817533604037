
import { defineComponent } from "vue";
import mixins from "@/mixins/index";
import axios from "axios";

import InfoTeachersCard from "@/components/cards/InfoTeachersCard.vue";
import InfoTeachersCardLoading from "@/components/cards/InfoTeachersCardLoading.vue";
import AltTeachersCard from "@/components/cards/AltTeachersCard.vue";
import SearchFailed from "@/components/Teachers/components/SearchFailed.vue";
import LoadingCard from "@/components/Teachers/components/LoadingCard.vue";
import PhoneSearchModal from "@/components/Teachers/components/PhoneSearchModal.vue";

interface categoryMapItem {
  backgroundImage: string;
  enName: string;
  name: string;
  status: boolean;
}

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  props: [
    "langnum",
    "langcategory",
    "categoryteacher",
    "defaulttags",
    "teacherloading",
  ],
  components: {
    InfoTeachersCard,
    InfoTeachersCardLoading,
    AltTeachersCard,
    SearchFailed,
    LoadingCard,
    PhoneSearchModal,
  },
  mixins: [mixins],
  data() {
    return {
      loading: true,
      message: "",
      // 標籤種類
      phoneActiveCategory: null as any,
      // 手機板語言分類
      activeCategory: 0,
      // 熱門教師
      teacherCardContents: [],
      // navbar樣式
      styleObject1: {
        backgroundColor: "#25a2a6",
        borderRadius: "15px",
        // width: "95%",
      },
      // filter師資介紹
      filterTeacherCardInformation: [] as any,
      tags: [],
      phoneTeacherloading: -1,
      // 語言選項
      categoryMap: [] as Array<categoryMapItem>,
    };
  },
  methods: {
    //手機板選擇哪種語言老師
    changeCategory(lang: number) {
      this.activeCategory = lang;
      this.phoneTeacherloading++;
      axios
        .get(`${serverUrl}teainfo?subject=${this.categoryMap[lang].enName}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.tags = res.data.data.tags;
            this.filterTeacherCardInformation = res.data.data.teachersInfo;
            this.loading = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //搜尋的結果
    clickSearchResult(query: string) {
      this.message = query;
      this.filterTeacherCardInformation = this.categoryteacher;
      this.filterTeacherCardInformation =
        this.filterTeacherCardInformation.filter((item: any) => {
          return (
            item.cnName.match(query.trim()) ||
            item.foreignName?.match(query.trim())
          );
        });
    },
    //手機版menu標籤
    menuCategory(num: number, show: number) {
      this.phoneActiveCategory = show;
      this.filterTeacherCardInformation = this.categoryteacher;
      this.filterTeacherCardInformation =
        this.filterTeacherCardInformation.filter((item: any) => {
          return item.tags?.some((i: any) => i == num);
        });
    },
  },
  watch: {
    langcategory(langcategory: any) {
      this.filterTeacherCardInformation = this.categoryteacher;
      this.filterTeacherCardInformation =
        this.filterTeacherCardInformation.filter((item: any) => {
          return item.tags?.some((i: any) => i == langcategory);
        });
    },
    categoryteacher() {
      this.filterTeacherCardInformation = this.categoryteacher;
      this.loading = false;
    },
    teacherloading() {
      this.filterTeacherCardInformation = [];
      this.loading = true;
    },
    phoneTeacherloading() {
      this.filterTeacherCardInformation = [];
      this.loading = true;
    },
  },
  mounted() {
    axios
      .get(`${serverUrl}teainfo/popular`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.teacherCardContents = res.data.data.popularTeachers;
          this.tags = this.defaulttags;
        }
      })
      .catch((e) => {
        console.log(e);
      });

    // 取得所有可使用語言
    axios
      .get(`${serverUrl}common/subject`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let index = 0;
          for (const key in res.data.data.subject) {
            let item = {} as categoryMapItem;
            item.backgroundImage = `flag_${key}.svg`;
            item.enName = key;
            item.name = this.setLangCh2(key);
            item.status = index == 0 ? true : false;
            index += 1;
            this.categoryMap.push(item);
          }

          // console.log(this.categoryMap);
        }
      })
      .catch((error) => {
        console.error(error);
      });

    axios
      .get(`${serverUrl}teainfo?subject=en`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.tags = res.data.data.tags;
          this.filterTeacherCardInformation = res.data.data.teachersInfo;
          this.loading = false;
        }
      })
      .catch((e) => {
        console.log(e);
      });
  },
});
