
import { defineComponent } from "vue";

export default defineComponent({
  props: ["message"],
  data() {
    return {};
  },
  methods: {},
});
