
import { defineComponent } from "vue";
import RoundImageFlag from "@/components/image/RoundImageFlag.vue";
import { computeStyles } from "@popperjs/core";

export default defineComponent({
  name: "TeacherCard",
  components: { RoundImageFlag },
  props: ["teacherCardContent"],
  data() {
    return {
      name: [] as any,
    };
  },
  mounted() {
    this.name = [];
    this.name.push(this.teacherCardContent.foreignName);
    this.name = this.name.join(" / ");
  },
});
