
import { defineComponent } from "vue";
import axios from "axios";
import mixins from "@/mixins/index";

interface categoryMapItem {
  backgroundImage: string;
  enName: string;
  name: string;
  status: boolean;
}

const serverUrl = process.env.VUE_APP_SERVER;
const loginToken = `Bearer ${localStorage.getItem("token")}` as string;

export default defineComponent({
  mixins: [mixins],
  data() {
    return {
      activeCategory: 0,
      categoryName: "英文",
      categoryMap: [] as Array<categoryMapItem>,
      tags: [],
      tagsLoading: false,
    };
  },
  methods: {
    //顯示語言國旗
    langImg(Img: string) {
      return require(`@/assets/icons/flags/${Img}`);
    },
    //顯示下方標籤
    langShow(langLabel: number) {
      this.categoryMap.forEach(function (item) {
        item.status = false;
      });
      this.categoryMap[langLabel].status = true;
    },
    //屬於哪個語言老師
    changeCategory(updatedCategoryIndex: number, updatedCategory: string) {
      this.activeCategory = updatedCategoryIndex;
      this.categoryName = this.categoryMap[updatedCategoryIndex].name;
      // this.$emit("emitLanguage", this.activeCategory);
      this.$emit("emitLanguage", updatedCategory);
    },
    //屬於該語言的所有老師
    teacherShow(teacherLabel: number) {

      const myMap = new Map();
      this.categoryMap.forEach((ele: categoryMapItem, index: number) => {
        myMap.set(index, ele.enName);
      });
      // console.log(myMap)
      this.tags = [];
      this.tagsLoading = true;
      this.$emit("emitloading", teacherLabel);
      axios
        .get(`${serverUrl}teainfo?subject=${myMap.get(teacherLabel)}`, {
          headers: {
            Authorization: loginToken,
          },
        })
        .then((res) => {
          if (res.status == 200) {
            this.tags = res.data.data.tags;
            this.$emit("emit-teacher", res.data.data.teachersInfo);
            this.tagsLoading = false;
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },
    //屬於哪個標籤
    changeLabelCategory(updatedLabelCategory: any) {
      this.$emit("emit-lala", updatedLabelCategory);
    },
  },
  mounted() {

    axios
      .get(`${serverUrl}teainfo?subject=en`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          this.tags = res.data.data.tags;
          this.$emit(
            "emit-teacher",
            res.data.data.teachersInfo,
            res.data.data.tags
          );
        }
      })
      .catch((e) => {
        console.log(e);
      });

    // 取得系統所有語言
    axios
      .get(`${serverUrl}common/subject`, {
        headers: {
          Authorization: loginToken,
        },
      })
      .then((res) => {
        if (res.status == 200) {
          let index = 0;
          for (const key in res.data.data.subject) {
            let item = {} as categoryMapItem;
            item.backgroundImage = `flag_${key}.svg`;
            item.enName = key;
            item.name = this.setLangCh2(key);
            item.status = index == 0 ? true : false;
            index += 1;
            this.categoryMap.push(item);
          }

          // console.log(this.categoryMap)
        }
      })
      .catch((error) => {
        console.error(error);
      });
  },
});
