
import { defineComponent } from "vue";

export default defineComponent({
  props: ["teacherlist"],
  data() {
    return {
      name: [] as any,
    };
  },
  methods: {
    //老師照片
    teacherImg(img: string) {
      return img;
    },
  },

  // mounted() {
  //   this.name = [];
  //   if (this.teacherlist.foreignName != null) {
  //     this.name.push(this.teacherlist.cnName, this.teacherlist.foreignName);
  //   } else {
  //     this.name.push(this.teacherlist.cnName);
  //   }

  //   this.name = this.name.join("/");
  //   console.log("this.name3");
  //   console.log(this.name);
  // },
});
